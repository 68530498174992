<template>
  <table v-if="data">
    <thead>
      <tr>
        <td>
          <!--place holder for the fixed-position header-->
          <div class="print-header">
            <ReportHeader
              :data="data"
              :similarity-level="similarityLevel"
            />
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <!--*** CONTENT GOES HERE ***-->
          <template v-if="data">
            <!-- <ReportPage> -->
            <!-- Header -->
            <div class="header">
              <ReportHeader
                :data="data"
                :similarity-level="similarityLevel"
              />
            </div>
            <!-- Body -->
            <v-row>
              <v-col
                class="py-0"
              >
                <!-- General Info -->
                <template v-if="isCorporateEntity || isIndividualEntity">
                  <v-card-title
                    class="pb-0"
                  >
                    {{ $t('dialogs.screeningDetail.general._title') }}
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <table>
                      <template
                        v-for="(field, key) in (
                          isCorporateEntity
                          ? ['businessName'] : (
                            isIndividualEntity
                            ? ['title', 'firstName', 'middleame', 'lastName', 'gender', 'country', 'dateOfBirth', 'dateOfDeath']
                            : []
                          )
                        )"
                      >
                        <tr
                          height="23px"
                          :key="key"
                          v-if="!(field === 'middleame' && !data[field])"
                        >
                          <td width="170px">
                            <span>
                              {{ $t(`dialogs.screeningDetail.general.${field}.label`) }}
                            </span>
                          </td>
                          <td>
                            <span class="primary--text font-weight-bold">
                              {{ data[field] || '-' }}
                            </span>
                          </td>
                        </tr>
                      </template>
                    </table>
                  </v-card-text>
                </template>
                <!-- Contact Info -->
                <template v-if="isCorporateEntity || isIndividualEntity">
                  <v-card-title
                    class="pb-0"
                  >
                    {{ $t(`dialogs.screeningDetail.contact.title`) }}
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <table>
                      <tr
                        v-for="(field, key) in (
                          isCorporateEntity
                          ? ['businessPhone', 'fax', 'website']
                          : (
                            isIndividualEntity
                            ? ['homePhone', 'mobilePhone', 'fax', 'email']
                            : []
                            )
                        )"
                        height="23px"
                        :key="key"
                      >
                        <td width="170px">
                          <span>
                            {{ $t(`dialogs.screeningDetail.contact.${field}.label`) }}
                          </span>
                        </td>
                        <td>
                          <span class="primary--text font-weight-bold">
                            {{ data[field] || '-' }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </v-card-text>
                </template>
              </v-col>
              <v-col
                style="max-width: 250px"
                class="pr-6 py-0"
              >
                <!-- Picture -->
                <img
                  :src="data.picture || defaultPortraitIcon"
                  :style="data.picture ? 'border-radius: 25px;width: 100%;' : 'width: 100%;'"
                  class="mt-4"
                >
                <!-- Category -->
                <div class="entity-category">
                  <ScreeningCategoryChips :category="data.category" />
                </div>
              </v-col>
            </v-row>
            <!-- Name Info -->
            <template>
              <v-card-title
                class="pb-0"
              >
                {{ $t('dialogs.screeningDetail.name.title') }}
              </v-card-title>
              <v-card-text>
                <table>
                  <tr
                    height="17.3px"
                    class="blue lighten-5"
                  >
                    <td width="170px">
                      <span class="spanText primary--text font-weight-bold">
                        {{ $t('dialogs.screeningDetail.name.primaryName') }}
                      </span>
                    </td>
                    <td>
                      <span class="pr-2 primary--text font-weight-bold">
                        {{ data.businessName || [data.firstName, data.middleame, data.lastName].filter(Boolean).join(' ') || '-' }}
                      </span>
                    </td>
                  </tr>
                  <tr
                    v-for="(_name, index) in data.names.filter((nameA, index) => data.names.findIndex(nameB => nameB.fullName.trim() === nameA.fullName.trim()) === index && nameA.fullName.trim() !== [data.firstName, data.middleame, data.lastName].filter(Boolean).join(' ').trim())"
                    :key="index"
                    height="17.3px"
                    :class="index%2 == 0? 'white' : 'blue lighten-5'"
                  >
                    <td width="170px">
                      <span
                        v-if="index == 0"
                        class="spanText primary--text font-weight-bold"
                      >
                        {{ $t('dialogs.screeningDetail.name.aka') }}
                      </span>
                    </td>
                    <td>
                      <span class="pr-2 primary--text font-weight-bold">
                        {{ _name.fullName }}
                      </span>
                    </td>
                  </tr>
                </table>
              </v-card-text>
            </template>
            <!-- Addresses -->
            <template v-if="(isCorporateEntity || isIndividualEntity) && data.addresses.length > 0">
              <v-card-title class="pb-0">
                {{ $t('dialogs.screeningDetail.address.title') }}
              </v-card-title>
              <v-card-text>
                <table class="simple">
                  <thead>
                    <tr>
                      <th>{{ $t('report.screeningEntity.address.location') }}</th>
                      <th>&nbsp;</th>
                      <th width="60px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(address, index) in data.addresses"
                      :key="`address_${index}`"
                    >
                      <td>
                        {{ [ address.addressLine1, address.addressLine2, address.addressLine3, address.addressLine4, address.townCity, address.countyState ].filter(Boolean).join(', ') }}
                      </td>
                      <td
                        width="100px"
                        style="white-space: nowrap;"
                      >
                        <flag
                          :iso="address.countryISO2"
                          :squared="false"
                          style="font-size:16px"
                        />
                        {{ address.country }}
                      </td>
                      <td class="font-italic">
                        {{ address.softDelete == 'Y' ? $t('report.screeningEntity.deleted') : '' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </template>
            <!-- Associations -->
            <template v-if="(isCorporateEntity || isIndividualEntity) && data.associations.length > 0">
              <v-card-title
                class="pb-0"
              >
                {{ $t('dialogs.screeningDetail.association.title') }}
              </v-card-title>
              <v-card-text
                class="pb-0"
              >
                <table class="simple">
                  <thead>
                    <tr>
                      <th>{{ $t('report.screeningEntity.association.name') }}</th>
                      <th style="white-space: nowrap;">
                        {{ $t('report.screeningEntity.association.type') }} | {{ $t('report.screeningEntity.association.id') }}
                      </th>
                      <th>{{ $t('report.screeningEntity.association.description') }}</th>
                      <th width="60px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(association, key) in data.associations.slice(0)"
                      :key="key"
                    >
                      <td>
                        {{
                          (association.name || []).concat([{ type: 'placeholder', fullName: 'N/A' }]).sort((a, b) => {
                            return ['Primary Name', 'Also Known As', 'placeholder'].indexOf(a.type) - ['Primary Name', 'Also Known As', 'placeholder'].indexOf(b.type)
                          })[0].fullName
                        }}
                      </td>
                      <td style="white-space: nowrap">
                        {{ association.associationType.toLowerCase() }} | {{ association.associationID }}
                      </td>
                      <td>{{ association.description }}</td>
                      <td class="font-italic">
                        {{ association.softDelete == 'Y' ? $t('report.screeningEntity.deleted') : '' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </template>
            <!-- Notes -->
            <template v-if="(isCorporateEntity || isIndividualEntity) && data.notes.length > 0">
              <v-card-title
                class="pb-0"
              >
                {{ $t('dialogs.screeningDetail.note.title') }}
              </v-card-title>
              <v-card-text
                class="pb-0"
              >
                <table class="simple">
                  <thead>
                    <tr>
                      <th>{{ $t('report.screeningEntity.note.source') }}</th>
                      <th>{{ $t('report.screeningEntity.note.content') }}</th>
                      <th width="60px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(note, index) in data.notes"
                      :key="`note_${index}`"
                    >
                      <td>{{ note.source || '' }}</td>
                      <td>{{ note.description || '' }}</td>
                      <td class="font-italic">
                        {{ note.softDelete == 'Y' ? $t('report.screeningEntity.deleted') : '' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </template>
            <!-- Articles -->
            <template v-if="(isCorporateEntity || isIndividualEntity) && data.articles.length > 0">
              <v-card-title
                class="pb-0"
              >
                {{ $t('dialogs.screeningDetail.article.title') }}
              </v-card-title>
              <v-card-text class="pb-0">
                <table class="simple">
                  <thead>
                    <tr>
                      <th>{{ $t('report.screeningEntity.article.url') }}</th>
                      <th>{{ $t('report.screeningEntity.article.category') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(article, key) in data.articles.slice(0)"
                      :key="key"
                    >
                      <td>
                        <a :href="article.originalUrl">{{ article.originalUrl }}</a>
                        <div
                          v-if="article.c6Url"
                          class="font-weight-regular grey--text px-2"
                        >
                          ({{ $t('report.screeningEntity.article.backupUrl') }}: <a :href="article.c6Url">{{ article.c6Url }}</a>)
                        </div>
                      </td>
                      <td>
                        <span style="white-space: nowrap;">
                          {{ article.source }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </template>
            <!-- Sanction -->
            <template v-if="(isCorporateEntity || isIndividualEntity) && data.sanctions.length > 0">
              <v-card-title
                class="pb-0"
              >
                {{ $t('dialogs.screeningDetail.sanction.title') }}
              </v-card-title>
              <v-card-text class="pb-0">
                <table class="simple">
                  <thead>
                    <tr>
                      <th width="476px">
                        {{ $t('report.screeningEntity.sanction.sanctionList') }}
                      </th>
                      <th>{{ $t('report.screeningEntity.sanction.status') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(sanction, key) in data.sanctions.slice(0).sort((a, b) => {
                        return a.current > b.current ? -1 : 1
                      })"
                      :key="key"
                    >
                      <td width="476px">
                        {{ sanction.sanctionBody }}
                      </td>
                      <td>
                        {{ sanction.current == 'Y' ? $t('report.screeningEntity.sanction.active') : $t('report.screeningEntity.sanction.inactive') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </template>
            <!-- Political Positions -->
            <template v-if="(isCorporateEntity || isIndividualEntity) && data.politicalPosition.length > 0">
              <v-card-title
                class="pb-0"
              >
                {{ $t('dialogs.screeningDetail.politicalPosition.title') }}
              </v-card-title>
              <v-card-text class="pb-0">
                <table class="simple">
                  <thead>
                    <tr>
                      <th>{{ $t('report.screeningEntity.politicalPosition.country') }}</th>
                      <th>{{ $t('report.screeningEntity.politicalPosition.duration') }}</th>
                      <th>{{ $t('report.screeningEntity.politicalPosition.position') }}</th>
                      <th width="60px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in data.politicalPosition.slice(0)"
                      :key="key"
                    >
                      <td>
                        <span style="white-space: nowrap;">{{ item.country }}</span>
                      </td>
                      <td>
                        <span style="white-space: nowrap;">
                          {{ [item.from, item.to].filter(Boolean).join(' - ') }}
                        </span>
                      </td>
                      <td>{{ item.description }}</td>
                      <td class="font-italic">
                        {{ item.softDelete == 'Y' ? $t('report.screeningEntity.deleted') : '' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </template>
            <!-- Footer -->
            <div class="footer">
              <ReportFooter
                :organization="organization"
                :export-by="exportBy"
                :export-at="exportAt"
              />
            </div>
            <!-- </ReportPage> -->
          </template>
          <v-container
            v-else
            fill-height
          >
            <div class="mx-auto text-h5 text-center">
              No report data attached. <br>
              Please trigger this report again.
            </div>
          </v-container>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <!--place holder for the fixed-position footer-->
          <div
            class="page-footer-space"
            style="height: 110px"
          >
            <!-- I'm The Footer -->
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import DefaultPortraitIcon from '@/assets/default_portrait_picture.svg'
import ScreeningCategoryChips from '@/components/common/ScreeningCategoryChips'
import ReportFooter from '@/components/reports/common/ReportFooter'
// import ReportPage from '@/components/reports/common/ReportPage'
import ReportHeader from '@/components/reports/ScreeningEntity/ReportHeader'

export default {
  components: {
    ScreeningCategoryChips,
    ReportFooter,
    ReportHeader
    // ReportPage
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    organization: {
      type: String,
      default: ''
    },
    exportBy: {
      type: String,
      default: ''
    },
    exportAt: {
      type: String,
      default: ''
    },
    similarityLevel: {
      type: String,
      default: null
    }
  },
  computed: {
    defaultPortraitIcon () {
      return DefaultPortraitIcon
    },
    isCorporateEntity () {
      return this.data.entityType.toLowerCase() !== 'individual'
    },
    isIndividualEntity () {
      return this.data.entityType.toLowerCase() === 'individual'
    }
  }
}
</script>

<style scoped>

.page {
  width: 21cm;
  min-height: 29.7cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  /* extra */
  overflow: hidden;
  position: relative;
  font-family: "Roboto", sans-serif;
}

@media print {
  thead {display: table-header-group;}
  tfoot {display: table-footer-group;}

  /* button {display: none;} */

  body {margin: 0;}

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: 21cm;
    min-height: 29.7cm;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    overflow: hidden;
    /* page-break-inside: avoid */
  }
  .v-application--wrap {
    background: none;
  }
  .header, .footer {
    display: none;
  }

  .print-header {
    display: block !important;
    position: relative;
    width: 100%;
    height: 110px;
    z-index: 9999;
    top: 0;
    left: 0;
  }

  /* .print-footer {
    display: block !important;
    position: fixed;
    width: 100%;
    height: 110px;
    z-index: 9999;
    bottom: 0;
    left: 0;
  } */

  /* .pagebreak {
    clear: both;
    page-break-after: always;
  } */

  .page-footer-space {
    display: block !important;
  }
}

.print-header {
  width: 21cm;
  display: none;
}

.spanText {
  display: inline-block;
  /* width: 170px; */
  padding-left: 6px;
}

.footer {
  user-select: none;
  padding-bottom: 140px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table.simple {
  width: 100%;
  border-collapse: collapse;
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

table.simple tr {
  height: 19px;
}

table.simple tbody tr:nth-child(odd) {
  background-color: #E3F2FD !important;
  border-color: #E3F2FD !important;
}

table.simple tbody tr:nth-child(even) {
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

table.simple th {
  color: #757575 !important;
  caret-color: #757575 !important;
  text-align: left !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
}

table.simple td {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
  font-weight: 700 !important;
}

table.simple th, table.simple td {
  padding: 0px 7px;
  word-break: break-all;
}

a { text-decoration: none; }
a:hover { text-decoration: inherit; }

.page-footer-space { display: none; }

</style>
