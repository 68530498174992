var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.category),function(i,index){return _c('v-chip',{key:index,staticClass:"ma-1 px-3 white--text font-weight-medium",attrs:{"small":"","color":i == 'Sanction'? 'red': (
      i == 'PEP'? 'blue darken-3': (
        i == 'Law Enforcement'? 'green': (
          i == 'Adverse Media'? 'orange':(
            i == 'Disqualified Director'? 'amber': (
              i == 'Insolvency'? 'indigo': (
                i == 'Financial Regulator'? 'pink': (
                  i == 'Sanction (Active)'? 'red': (
                    i == 'Sanction (Inactive)'? 'grey': 'brown'
                  )
                )
              )
            )
          )
        )
      )
    )}},[_vm._v(" "+_vm._s(i)+" ")])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }