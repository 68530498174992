<template>
  <div class="report-header">
    <img
      src="@/assets/reports/logo.webp"
      class="report-app-logo"
    >
    <img
      src="@/assets/reports/Subtraction_46.svg"
      class="report-bg"
    >
    <v-card-title
      class="pb-0"
    >
      <v-row>
        <v-col
          cols="4"
          class="py-0"
        >
          <span
            class="font-weight-bold grey--text text--darken-1 pr-5"
          >
            {{ $t('dialogs.screeningDetail.entityID') }}
          </span>
          <span
            class="primary--text font-weight-bold"
          >
            {{ data.entityID }}
          </span>
        </v-col>
        <v-col
          cols="4"
          class="py-0"
        >
          <span
            class="font-weight-bold grey--text text--darken-1 pr-5"
          >
            {{ $t('dialogs.screeningDetail.entityType.label') }}
          </span>
          <v-icon
            v-if="String(data.entityType).toUpperCase() == 'INDIVIDUAL'"
            color="blue lighten-3"
          >
            mdi-account
          </v-icon>
          <v-icon
            v-else
            color="blue lighten-3"
          >
            mdi-bank
          </v-icon>
          <span
            class="primary--text text-capitalize font-weight-bold"
          >
            {{ String(data.entityType).toUpperCase() == 'INDIVIDUAL' ? $t('dialogs.screeningDetail.entityType.individual') : $t('dialogs.screeningDetail.entityType.corporate') }}
          </span>
        </v-col>
        <v-col
          cols="4"
          class="py-0"
        >
          <template v-if="similarityLevel">
            <span
              class="font-weight-bold grey--text text--darken-1 pr-5"
            >
              {{ $t('report.screeningEntity.similarity.label') }}
            </span>
            <span class="primary--text text-capitalize">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="similarityLevel === 'EXACT'"
                color="error"
              >
                mdi-signal-cellular-3
              </v-icon>
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-else-if="similarityLevel === 'STRONG'"
                color="warning"
              >
                mdi-signal-cellular-2
              </v-icon>
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-else-if="similarityLevel === 'WEAK'"
                color="success"
              >
                mdi-signal-cellular-1
              </v-icon>
              <v-icon v-else>
                signal-cellular-outline
              </v-icon>
              <span
                v-if="similarityLevel === 'EXACT'"
                class="error--text"
              >{{ $t('report.screeningEntity.similarity.exact') }}</span>
              <span
                v-else-if="similarityLevel === 'STRONG'"
                class="warning--text"
              >{{ $t('report.screeningEntity.similarity.strong') }}</span>
              <span
                v-else-if="similarityLevel === 'WEAK'"
                class="success--text"
              >{{ $t('report.screeningEntity.similarity.weak') }}</span>
              <span
                v-else
                class="grey--text"
              >N/A</span>
            </span>
          </template>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="mx-3" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    similarity: {
      type: Number,
      default: 0
    },
    similarityLevel: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.report-header {
  padding: 68px 0px 0px;
}

.report-bg {
  position: absolute;right: 0;top: 0;height: 110px;user-select: none;
}

.report-app-logo {
  top: 22px;height: 58px;left: 24px;position: absolute;user-select: none;
}
</style>
