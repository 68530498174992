<template>
  <div>
    <v-chip
      v-for="(i, index) in category"
      :key="index"
      small
      class="ma-1 px-3 white--text font-weight-medium"
      :color="i == 'Sanction'? 'red': (
        i == 'PEP'? 'blue darken-3': (
          i == 'Law Enforcement'? 'green': (
            i == 'Adverse Media'? 'orange':(
              i == 'Disqualified Director'? 'amber': (
                i == 'Insolvency'? 'indigo': (
                  i == 'Financial Regulator'? 'pink': (
                    i == 'Sanction (Active)'? 'red': (
                      i == 'Sanction (Inactive)'? 'grey': 'brown'
                    )
                  )
                )
              )
            )
          )
        )
      )"
    >
      {{ i }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style>

</style>
