<template>
  <div class="page">
    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style>
.page {
  width: 21cm;
  min-height: 29.7cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  /* extra */
  overflow: hidden;
  position: relative;
  font-family: "Roboto", sans-serif;
  break-inside: avoid;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: 21cm;
    min-height: 29.7cm;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    overflow: hidden;
    /* page-break-inside: avoid */
  }
}

</style>
